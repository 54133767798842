import "../scss/main.scss";
import 'slick-carousel';

window.addEventListener('DOMContentLoaded', function(e) {
    var height = window.innerHeight - $('#masthead').height() - 150;
    $('iframe#plateau').height(height);
    $('iframe#plateau').width('100%');
} );

$(function(){

    var adminBar;
    if( $('#wpadminbar').length != 0 ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }
    //$('main').css( 'padding-top', $('#masthead').innerHeight() );

    // if( adminBar.length != 0){
    //     $('#breadcrumb').css( 'top', $('#masthead').innerHeight() + adminBar );
    // }else{
    //     $('#breadcrumb').css( 'top', $('#masthead').innerHeight() );
    // }

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });

    $('.toggle-login-form').on('click', function(){
        $('.login-form').addClass('active');
    });

    $('.toggle-search-form').on('click', function(){
        $('.search-form').addClass('active');
    });

    $('.login-form .fa-xmark').on('click', function(){
        $('.login-form').removeClass('active');
    });

    $('.search-form .fa-xmark').on('click', function(){
        $('.search-form').removeClass('active');
    });    
    
    $('.bloc--pageperso .bloc--tweets').css('max-height', $('.bloc--pageperso .bloc--first').height());

    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    if ($( "body" ).hasClass( "home") && $( "body" ).hasClass( "logged-in")) {
        console.log('pas animation scroll');
    } else {
        animationScroll();
    }
    readmore();
});


function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: true,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
    });

    $('.bloc--actualites').not('.slick-initialized').slick({
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 1500,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-arrow slide-prev fa-light fa-chevron-left"></span>',
        nextArrow: '<span class="slide-arrow slide-next fa-light fa-chevron-right"></span>',
        responsive: [
            {
                breakpoint: 1330,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1
                }
              }
        ]
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function readmore(){
    const contentDiv = $('.content-more');
    const readMoreButton = $('#readMore');
    let isContentExpanded = false;

    if( contentDiv.height() > 249){
        readMoreButton.on('click', function() {
            if (isContentExpanded) {
                contentDiv.css('max-height', '250px');
                readMoreButton.html('<i class="fa-solid fa-plus"></i>');
            } else {
                contentDiv.css('max-height', 'none');
                readMoreButton.html('<i class="fa-solid fa-minus"></i>');
            }
            isContentExpanded = !isContentExpanded;
        });
    }else{
        readMoreButton.hide();
    }
}

if (jQuery('.pageperso-template-default').length != 0) {
    acf.add_action('ready', function( $el ) {
        var allowSubmit = false;
        jQuery('.acf-form-submit .button').click( function(event) {
            if (!allowSubmit) {
                event.preventDefault();
                var post_id = acf.get('post_id');
                bootbox.prompt({
                    title: "Est-ce une modification majeure, si oui merci de préciser le(s) type(s) de modification :",
                    buttons: {
                        cancel: {
                            label: "Non, modification mineure",
                            className: 'btn-danger'
                        }
                    },
                    inputType: 'checkbox',
                    inputOptions: [
                        {
                            text: 'Modification/Ajout/Suppression de texte',
                            value: 'modify_text',
                        },
                        {
                            text: 'Ajout de vidéo',
                            value: 'add_video',
                        },
                        {
                            text: 'Suppression de vidéo',
                            value: 'delete_video',
                        },
                        {
                            text: 'Ajout de document',
                            value: 'add_file',
                        },
                        {
                            text: 'Suppression de document',
                            value: 'delete_file',
                        }
                    ],
                    callback: function (result) {
                        if (result != null) {
                            jQuery.post(
                                ajaxurl,
                                {
                                    'action': 'log_message',
                                    'param': result,
                                    'post_id': post_id
                                },
                                function(response){
                                    console.log(response);
                                    allowSubmit = true;
                                    jQuery('#acf-form').submit();
                                }
                            );
                        } else {
                            console.log(result);
                            allowSubmit = true;
                            jQuery('#acf-form').submit();
                        }
                    }
                });
            } else {
                jQuery('#acf-form').submit();
            }
        });
    });	
};	    

if (jQuery('.necessite_consentement').length != 0) {
    bootbox.confirm({
        message: jQuery('.necessite_consentement').html(),
        buttons: {
            confirm: {
                label: 'J\'accepte',
                className: 'btn-success'
            },
            cancel: {
                label: "Non (votre compte sera désactivé)",
                className: 'btn-danger'
            }
        },
        callback: function (result) {
            if (result != false) {
                jQuery.post(
                    ajaxurl,
                    {
                        'action': 'user_cgu',
                        'value': '1',
                    },
                    function(response){
                    }
                );
            } else {
                jQuery.post(
                    ajaxurl,
                    {
                        'action': 'user_cgu',
                        'value': '0',
                    },
                    function(response){
                        location.reload();
                    }
                );
            }
        }
    });
};	

jQuery('#loginform .input').attr("size", "15");

jQuery('.profil select').on('click', function (ev) {
    if(ev.offsetY < 0){
        document.location = jQuery(this).val();
    }
});

jQuery('.lien_edit_front').on('click', function() {
    jQuery('.edit_front').toggle(500);
});

jQuery('.selected').on('click', function(event) {
    jQuery('.custom-sel').addClass('show-sel');
    jQuery('.custom-sel a').removeClass('hidden');
    event.preventDefault();
});


jQuery('.button_note').on('click', function(e) {
    e.preventDefault();
    jQuery('.form_hidden').hide();
    jQuery(this).parent().find('form').toggle();
});

if (jQuery('#acf-field_64955f2adb7d8').length) {
    var phase = jQuery('#acf-field_64955f2adb7d8').val();
    jQuery('.acf-field-5bf590fac1c23 .acf-row').each(function() {
        var dataid = jQuery(this).attr('data-id');
        var phase_elem = jQuery(this).find('.acf-field-6495a901c692d input').val();
        if (phase_elem < phase && dataid.includes('row-')) {
            jQuery(this).css('display', 'none');
        }
    });
    jQuery('.acf-field-5bf59119c1c26 .acf-row').each(function() {
        var phase_elem = jQuery(this).find('.acf-field-6495a910c692e input').val();
        var dataid = jQuery(this).attr('data-id');
        if (phase_elem < phase && dataid.includes('row-')) {
            jQuery(this).css('display', 'none');
        }
    });
}

function exportTableToCSV($table, filename) {

    var $rows = $table.find('tr:has(td),tr:has(th)'),

        // Temporary delimiter characters unlikely to be typed by keyboard
        // This is to avoid accidentally splitting the actual contents
        tmpColDelim = String.fromCharCode(11), // vertical tab character
        tmpRowDelim = String.fromCharCode(0), // null character

        // actual delimiter characters for CSV format
        colDelim = '","',
        rowDelim = '"\r\n"',

        // Grab text from table into CSV formatted string
        csv = '"' + $rows.map(function (i, row) {
            var $row = jQuery(row), $cols = $row.find('td,th');

            return $cols.map(function (j, col) {
                if (j < 9) {
                    var $col = jQuery(col), text = $col.html();
                    text = text.replace('<br>', "\r\n");
                    text = text.replace('<br />', "\r\n");
                    text = text.replace('<i class="fa-solid fa-warning"></i>', "");
                    var hrefPattern = /<a (.+?) href="(.+?)"(.+?)>(.+?)<\/a>/g; 
                    var text = text.replace(hrefPattern, '$2');
                    var hrefPattern = /<a href="(.+?)"(.+?)>(.+?)<\/a>/g; 
                    var text = text.replace(hrefPattern, '$1');
                    return text.replace(/"/g, '""').trim(); // escape double quotes
                }

            }).get().join(tmpColDelim);

        }).get().join(tmpRowDelim)
            .split(tmpRowDelim).join(rowDelim)
            .split(tmpColDelim).join(colDelim) + '"',

        

        // Data URI
        csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);
        
        if (window.navigator.msSaveBlob) { // IE 10+
            //alert('IE' + csv);
            window.navigator.msSaveOrOpenBlob(new Blob([csv], {type: "text/plain;charset=utf-8;"}), "csvname.csv")
        } 
        else {
            jQuery(this).attr({ 'download': filename, 'href': csvData, 'target': '_blank' }); 
        }
}